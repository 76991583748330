import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f26522',
    },
    text: {
      primary: '#58595b',
      secondary: '#58595b',
    },
  },
  overrides: {
    MuiFilledInput: {
      input: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      },
      multiline: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      },
      inputMultiline: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      },
    },
    MuiInputLabel: {
      shrink: {
        fontWeight: 600,
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      },
    },
  },
});

export default theme;
