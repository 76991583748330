import wrapRootElement from './src/utils/wrap-root-element';

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(routerProps.location);

  if (routerProps?.location?.state?.preserveCurrentScrollPosition) {
    return false;
  }

  window.scrollTo(...(currentPosition || [0, 0]));
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export { shouldUpdateScroll, wrapRootElement };
