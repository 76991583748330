import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { OrchestrationContextWrapper } from './orchestrationContext';

const WrapRootElememt = ({ element }) => {
  return (
    <OrchestrationContextWrapper>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </OrchestrationContextWrapper>
  );
};

export default WrapRootElememt;
