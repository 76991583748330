exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-patterns-brand-jsx": () => import("./../../../src/pages/patterns/brand.jsx" /* webpackChunkName: "component---src-pages-patterns-brand-jsx" */),
  "component---src-pages-patterns-elements-jsx": () => import("./../../../src/pages/patterns/elements.jsx" /* webpackChunkName: "component---src-pages-patterns-elements-jsx" */),
  "component---src-pages-patterns-foundation-jsx": () => import("./../../../src/pages/patterns/foundation.jsx" /* webpackChunkName: "component---src-pages-patterns-foundation-jsx" */),
  "component---src-pages-patterns-index-jsx": () => import("./../../../src/pages/patterns/index.jsx" /* webpackChunkName: "component---src-pages-patterns-index-jsx" */),
  "component---src-patterns-element-jsx": () => import("./../../../src/patterns/element.jsx" /* webpackChunkName: "component---src-patterns-element-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

