import React, { createContext, useMemo, useState } from 'react';
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const OrchestrationContext = createContext({
  promotion: {
    litres: undefined,
    setLitres: () => {},
    code: null,
    setCode: () => {},
  },
  referrer: {
    code: null,
    setCode: () => {},
    name: null,
    setName: () => {},
  },
});

const OrchestrationContextWrapper = ({ children }) => {
  const [promotionLitres, setPromotionLitres] = useState(null);
  const [promotionCode, setPromotionCode] = useState(null);
  const [referrerCode, setReferrerCode] = useState(null);
  const [referrerName, setReferrerName] = useState(null);

  const memoized = useMemo(() => {
    return {
      sharedContext: {
        promotion: {
          litres: promotionLitres,
          setLitres: (value) => {
            setPromotionLitres(value);
          },
          code: promotionCode,
          setCode: (value) => {
            setPromotionCode(value);
          },
        },
        referrer: {
          code: referrerCode,
          setCode: (value) => {
            setReferrerCode(value);
          },
          name: referrerName,
          setName: (newNameValue) => {
            setReferrerName(newNameValue);
          },
        },
      },
    };
  }, [
    promotionLitres,
    setPromotionCode,
    setPromotionLitres,
    setReferrerCode,
    setReferrerName,
    promotionCode,
    referrerCode,
    referrerName,
  ]);

  return <OrchestrationContext.Provider value={memoized}>{children}</OrchestrationContext.Provider>;
};

export default OrchestrationContext;

export { OrchestrationContextWrapper };
